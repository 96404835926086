import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/lib/handbook/post-layout/post-layout-parent.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "a-how-does-the-school-manage-recess-and-snack-time",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#a-how-does-the-school-manage-recess-and-snack-time",
        "aria-label": "a how does the school manage recess and snack time permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`A. How Does The School Manage Recess And Snack Time?`}</h2>
    <h4 {...{
      "id": "school-day",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#school-day",
        "aria-label": "school day permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`School Day:`}</h4>
    <p>{`Our school day begins at 8:15 and ends at 15:15. Students should arrive at school by 8:05, and are marked late if they come in after 8:20.`}</p>
    <p>{`If your child arrives late to school or leaves early, we ask that Early Childhood (EC) and Kindergarten (KG) parents pick up or drop off their child at their homeroom classes during the school day. Grade 1 - Grade 5 students should be dropped off at security or reception to sign in and can make their own way to their classroom.`}</p>
    <h4 {...{
      "id": "recess-and-lunch-breaks",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#recess-and-lunch-breaks",
        "aria-label": "recess and lunch breaks permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Recess and Lunch Breaks`}</h4>
    <p>{`The School feels it is of utmost importance for children to play outside on a daily basis. The health and academic benefits of fresh air and active play are well researched. For that purpose, there is a fifteen-minute morning recess and a forty-minute lunch with recess. When weather permits, students play in the playground between Buildings A and B; if it is raining just a bit, students play under the covered pitch. Students are watched by teachers, teaching assistants and / or school leadership during recess times. Students must stay in the play area during recess and lunch break. Recess rules prohibit pushing, shoving, or fighting of any sort. The throwing of snowballs is not permitted. When weather conditions prohibit the use of the outside play areas, Primary students remain in their homerooms under the supervision of the homeroom teacher, monitor or teaching assistant (TA).`}</p>
    <h4 {...{
      "id": "recess-and-weather",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#recess-and-weather",
        "aria-label": "recess and weather permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Recess and Weather`}</h4>
    <p>{`There are times when children cannot play outside. PSI practice is as follows:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Children are inside on days with heavy rain and thunderstorms. The school leadership makes the decision and informs teachers.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Children play outside when it snows.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`If there is snow on the ground, only children with snow pants and boots (as well as mittens and hats) may play in the snow. Other children are limited to the covered pitch.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`When temperatures are between -10C and -15C, the decision is based on the wind chill factor.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Children are inside when the temperature falls below -15C`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`In addition to inclement weather, PSI also daily monitors air quality. `}<a parentName="p" {...{
            "href": "https://docs.google.com/document/d/19q58k-TbFhmlXOIclWa941AUTlw_0rQXIPnbd8d4Bt4/edit"
          }}>{` Guidelines `}</a>{` have been set for when children can play outdoors or must remain indoors during recess times.`}</p>
      </li>
    </ul>
    <h4 {...{
      "id": "snack-time",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#snack-time",
        "aria-label": "snack time permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Snack Time`}</h4>
    <p>{`Morning snack times are determined by the homeroom teacher / grade level teams; but are typically before or after morning recess and last approximately 10 - 15 minutes. All Primary School students are asked to bring in a healthy snack for their morning snack time, which takes place in their homeroom. Healthy snacks include sandwiches, fresh vegetables, cheese, yogurt, fruit and the like. Students are not allowed to bring in chocolates, lollies, other types of candy or ‘junk food’ for snacks. Eating something healthy helps children stay focused and gives them energy to continue to work and play. Parents can also subscribe to the cafeteria’s healthy morning snack program on a monthly basis. In these cases, the cafeteria brings a healthy morning snack to the child’s homeroom class by 9:30 am.`}</p>
    <p>{`All students should have a reusable water bottle to fill at our water dispensers around the school throughout the day.`}</p>
    <h2 {...{
      "id": "b-how-much-time-does-my-child-get-with-specialist-teachers",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#b-how-much-time-does-my-child-get-with-specialist-teachers",
        "aria-label": "b how much time does my child get with specialist teachers permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`B. How Much Time Does My Child Get With Specialist Teachers?`}</h2>
    <p><strong parentName="p">{`Early Childhood`}</strong><br parentName="p"></br>{`
`}{`Physical Education for 3 and 4 year olds: 4 x 40 minute periods per 6 day rotation`}</p>
    <p>{`Music 1 x 30 minutes per week per six day rotation`}</p>
    <p>{`Art 1 x 30 minutes per week per six day rotation`}</p>
    <p>{`Host Country Culture 1 x 30 minutes per six day rotation`}</p>
    <p><strong parentName="p">{`Kindergarten`}</strong><br parentName="p"></br>{`
`}{`Physical Education 2 x 40 and 1 x 80 minute periods per six day rotation`}<br parentName="p"></br>{`
`}{`Music 2 x 40 minute periods per six day rotation`}<br parentName="p"></br>{`
`}{`Art 2 x 40 minute periods per six day rotation`}<br parentName="p"></br>{`
`}{`English as an Additional Language (EAL) as a push-in model 3 x 40 minutes per six day rotation`}<br parentName="p"></br>{`
`}{`Host Country Culture 1 x 40 minutes per six day rotation`}</p>
    <p><strong parentName="p">{`Grade 1`}</strong><br parentName="p"></br>{`
`}{`Physical Education 2 x 40 minute and 1 x 80 minute per six day rotation`}</p>
    <p>{`Music 2 x 40 minute periods per six day rotation`}<br parentName="p"></br>{`
`}{`Art 2 x 40 minute periods per six day rotation`}<br parentName="p"></br>{`
`}{`Additional Language (Ukrainian, French, Russian, Spanish, EAL) 4 x 40 minute per six day rotation`}</p>
    <p>{`Host Country Culture 1 x 30 minutes per six day rotation`}</p>
    <p><strong parentName="p">{`Grades 2 through 5`}<br parentName="strong"></br>
      </strong>{`Physical Education 2 x 40 minute and 1 x 80 minute per six day rotation`}<br parentName="p"></br>{`
`}{`Music 2 x 40 minute periods per six day rotation`}<br parentName="p"></br>{`
`}{`Art 1 x 80 minute periods per six day rotation`}<br parentName="p"></br>{`
`}{`Additional Language (Ukrainian, French, Russian, Spanish, EAL)`}</p>
    <p>{`Grades 1-3: 5 x 40 minutes per six day rotation`}</p>
    <p>{`Grades 4-5: 6 x 40 minutes per six day rotation`}</p>
    <p>{`Library and IT skills are provided to students through integrated projects and lessons. The school librarian works in collaboration with grade level teams throughout the year. Children are allowed to check out books when needed or during scheduled classes.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      